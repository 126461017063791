.section-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  gap: 1rem;
}
.label-wrapper {
  display: flex;
  color: #475569;
  text-align: center;
  font-family: Source Sans Pro;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  margin: 0px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}
.heading-wrapper {
  display: flex;
  color: #0f172a;
  text-align: center;
  font-family: Source Sans Pro;
  font-size: 1.625rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  margin: 0px;
}
