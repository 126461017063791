.mainColumn {
  display: flex;
  flex-direction: row;
  flex: 100%;
  gap: 2rem;
  line-height: 15px;
}
.mainContainer {
  width: 595px;
  margin: 37px;
}
.wedbush {
  display: flex;
  justify-content: flex-end;
}
.header {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
}
.left {
  width: 100%;
}
.insideCard {
  font-size: 10px;
  display: flex;
  flex-direction: row;
}
.insideleft {
  width: 100%;
}
.padRight{
  padding: 0 0.35rem 0 0;
}
.insideright {
  width: 100%;
}
.cardWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  padding: 10px 0 15px 0;
}
.eachcard {
  /* padding: 0 0 15px; */
}
.header {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
}
.subheader {
  display: flex;
  font-weight: 600;
  font-size: 12px;
  line-height: 32px;
  color: #5d5d5d;
  justify-content: space-between;
  line-height: normal;
}
.sofHrLine {
  color: #5d5d5d;
  border: 1px dashed #a4a4a4;
}
.page2Header {
  font-weight: 600;
  size: 20px;
  line-height: 32px;
}
.page2Content {
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  text-align: justify;
}
.page4para {
  padding: 0 0 20px;
}
.columnWidth {
  display: flex;
  width: 100%;
  gap: 1rem;
}
.customhr {
  border: 1px solid #a4a4a4;
}
.pad {
  padding: 0 0 2px 0;
}
.textArea {
  height: 50px;
  width: 100%;
  border: none;
  resize: none;
}
/* -----------------------new css---------------- */
.gap {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}
.centerAlign {
  align-items: center;
}
.header-title {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.blue-Color{
  color: rgb(0, 36, 93);
}
.newDis {
  color: rgb(0, 36, 93);
  line-height: 20px !important;
}
.text-para {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 13px;
  font-variant: normal;
  line-height: 20px;
  text-align: justify;
}
.text-para-ends {
  line-height: 20px;
  font-size: 13px;
  font-variant: normal;
  text-align: justify;
}
.padding1rem {
  padding: 1rem;
}
.box-border {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem;
  margin-top: 1rem;
  border: 1px solid black;
}
.text-para-anchor {
  font-size: 13px;
  font-variant: normal;
  line-height: 20px;
  text-align: justify;
}
.paddingBottom {
  padding: 0 1rem ;
}
            

.bold-content {
  font-size: 14px;
  text-align: justify;
  color: rgb(0, 36, 93);
}